import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ContentSection } from "@ryerson/frontend.layout";
import { Grid, Row, Column } from "@ryerson/frontend.layout";
import { Media } from "@ryerson/frontend.layout";
import { Breadcrumb, Link } from "@ryerson/frontend.navigation";
import { Typography } from "@ryerson/frontend.typography";
import { Video } from "@ryerson/frontend.media";
import { VideoWrapperProps } from "@components/Helper/common";
import { getVideoPadding } from "@components/Helper/commonStyles";
import { Icon } from "@ryerson/frontend.assets";

export type HistoryHeroContent = {
	title: string;
	subTitle: string;
	description: string;
	videoThumbnail: string;
	videoOverlayText: string;
	videoId: string;
	downloadUrl: string;
	downloadText: string;
};

export type HistoryHeroProps = {
	content: HistoryHeroContent;
};

const BreadcrumbContainer = styled.div`
	margin-top: 32px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 8px;
			}
		`;
	}}
`;

const Divider = styled.div`
	width: 100%;
	opacity: 0.1;
	margin-top: 30px;
	margin-bottom: 42px;
	${(props: any) => {
		const { theme } = props;
		return css`
			border-bottom: 1px solid ${theme.colors.primary.white};
		`;
	}}
`;

const VideoWrapper = styled.div`
	${(props: VideoWrapperProps) => {
		const { theme, buttonOrientation = "left", buttonSize = "md" } = props;
		return css`
			@media only screen and (min-width: ${theme.breakpoints.lg}) {
				max-width: 100%;
				width: 620px;
				height: 360px;
				margin-bottom: 108px;
				${buttonOrientation === "center"
					? 0
					: getVideoPadding(buttonOrientation, false, buttonSize)};
			}
			@media only screen and (min-width: ${theme.breakpoints.lg}) and (max-width: ${theme
					.breakpoints.xl}) {
				max-width: 100%;
				width: 100%;
				margin-bottom: 108px;
			}
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				height: 230px;
				margin-bottom: 33px;
				${buttonOrientation === "center"
					? 0
					: getVideoPadding(buttonOrientation, true, buttonSize)};
			}
		`;
	}}
`;

const TextWrapper = styled.div`
	max-width: 460px;
`;

const IconLink = styled.div`
	display: block;
	max-width: 300px;
	width: 100%;
	padding-left: 25px;
	box-sizing: border-box;
	height: auto;
	margin-bottom: 44px;
	position: relative;
`;

const IconLinkIcon = styled.div`
	position: absolute;
	top: 2px;
	left: 0px;
	width: 20px;
	height: 20px;
`;

const HistoryHero: React.FC<HistoryHeroProps> = ({ content }) => {
	const { theme } = useTheme();

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Typography
						variant="h1"
						type="tertiary"
						css={css`
							font-size: 75px;
							margin-top: 32px;
							margin-bottom: 23px;
						`}
					>
						{content.title}
					</Typography>
					<Divider theme={theme} />
					<Grid>
						<Row>
							<Column lg={6}>
								<TextWrapper>
									<Typography
										variant="p"
										type="tertiary"
										size="lg"
										css={{ marginBottom: "44px" }}
									>
										{content.subTitle}
									</Typography>
									<Typography
										variant="p"
										type="tertiary"
										size="md"
										css={{ marginBottom: "50px" }}
									>
										{content.description}
									</Typography>
									<Link to={content.downloadUrl} gatsby={false} target="_blank">
										<IconLink>
											<IconLinkIcon>
												<Icon
													icon="file-pdf"
													size="fit"
													color={theme.colors.primary.link}
												/>
											</IconLinkIcon>
											<Typography
												variant="h4"
												color={theme.colors.primary.link}
												css={css`
													font-size: 20px;
													line-height: 25px;
													letter-spacing: -1px;
												`}
											>
												{content.downloadText}
											</Typography>
										</IconLink>
									</Link>
								</TextWrapper>
							</Column>
							<Column lg={6}>
								<VideoWrapper theme={theme}>
									<Video
										videoId={content.videoId}
										imageUrl={content.videoThumbnail}
										buttonOrientation="left"
										text={content.videoOverlayText}
									></Video>
								</VideoWrapper>
							</Column>
						</Row>
					</Grid>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="tertiary">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Typography
						variant="h1"
						type="tertiary"
						css={css`
							margin-top: 15px;
							margin-bottom: 30px;
						`}
					>
						{content.title}
					</Typography>
					<Typography
						variant="p"
						type="tertiary"
						size="lg"
						color={theme.colors.primary.white}
					>
						{content.subTitle}
					</Typography>
					<VideoWrapper theme={theme} buttonOrientation="top-right">
						<Video
							videoId={content.videoId}
							imageUrl={content.videoThumbnail}
							buttonOrientation="top-right"
							text={content.videoOverlayText}
						></Video>
					</VideoWrapper>
					<Typography
						variant="p"
						type="tertiary"
						size="md"
						css={{ marginBottom: "30px" }}
					>
						{content.description}
					</Typography>
					<Link to={content.downloadUrl} gatsby={false} target="_blank">
						<IconLink
							css={css`
								max-width: 100%;
								padding-left: 25px;
								margin-bottom: 35px;
							`}
						>
							<IconLinkIcon
								css={css`
									top: 0px;
								`}
							>
								<Icon
									icon="file-pdf"
									size="fit"
									color={theme.colors.primary.link}
								/>
							</IconLinkIcon>
							<Typography variant="div" size="lg" color={theme.colors.primary.link}>
								{content.downloadText}
							</Typography>
						</IconLink>
					</Link>
				</ContentSection>
			</Media>
		</>
	);
};

export default HistoryHero;
